import { Co2FootprintVO } from 'vo';
import { AirlineTicketClass, FlightFrequencyKind } from 'common/types';
import * as utils from 'utils';

const PASSENGER_AVG_CO2e_PER_KM = 0.18181;

const getCo2FootprintCoeffByAirlineTicketClass = (airlineTicketClass: AirlineTicketClass) => {
  const mapping: Record<AirlineTicketClass, number> = {
    [AirlineTicketClass.ECONOMY]: 0.139245,
    [AirlineTicketClass.BUSINESS]: 0.40379,
    [AirlineTicketClass.FIRST_CLASS]: 0.55695,
  };
  return mapping[airlineTicketClass];
};

const getCo2FootprintCoeffByFlightFrequencyKind = (flightFrequencyKind: FlightFrequencyKind) => {
  const mapping: Record<FlightFrequencyKind, number> = {
    [FlightFrequencyKind.RARELY]: 1,
    [FlightFrequencyKind.OCCASIONALLY]: 3,
    [FlightFrequencyKind.REGULARLY]: 5,
  };
  return mapping[flightFrequencyKind];
};

type Props = {
  airlineTicketClass?: AirlineTicketClass;
  flightFrequencyKind?: FlightFrequencyKind;
  singleShortFlightsNumber?: number;
  singleMidRangeFlightsNumber?: number;
  singleLongHaulFlightsNumber?: number;
};

export default (props: Props) => {
  const {
    airlineTicketClass = AirlineTicketClass.ECONOMY,
    flightFrequencyKind,
    singleShortFlightsNumber = 0,
    singleMidRangeFlightsNumber = 0,
    singleLongHaulFlightsNumber = 0,
  } = props;

  const airlineTicketClassCoeff = getCo2FootprintCoeffByAirlineTicketClass(airlineTicketClass);

  if (flightFrequencyKind) {
    const AVG_DISTANCE = 4667;
    const flightFrequencyCoeff = getCo2FootprintCoeffByFlightFrequencyKind(flightFrequencyKind);
    const resultCo2FootprintKg =
      AVG_DISTANCE * PASSENGER_AVG_CO2e_PER_KM * flightFrequencyCoeff * airlineTicketClassCoeff;
    return Co2FootprintVO.create(resultCo2FootprintKg, 'kg');
  }

  // flights nubmer * average flight distance km * flight co2 coeff
  const singleShortFlightsCo2FootprintKg = singleShortFlightsNumber * 1500 * 0.2443;
  const singleMidRangeFlightsCo2FootprintKg = singleMidRangeFlightsNumber * 3500 * 0.15553;
  const singleLongHaulFlightsCo2FootprintKg = singleLongHaulFlightsNumber * 5000 * 0.19085;

  const resultCo2FootrpintKg =
    utils.sumList([
      singleShortFlightsCo2FootprintKg,
      singleMidRangeFlightsCo2FootprintKg,
      singleLongHaulFlightsCo2FootprintKg,
    ]) * airlineTicketClassCoeff;

  return Co2FootprintVO.create(resultCo2FootrpintKg, 'kg');
};
