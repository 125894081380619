import { Hints, RadioButton, QuestionTitle } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { ShoppingHabitsKind } from 'common/types';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as validators from 'validators';

const translationPrefix = 'dubaiPersonal.pages.shopping';

const fieldName = 'shoppingHabitsKind';

export const Shopping = () => {
  const { t } = useTranslation();
  const options = [
    {
      label: t(translationPrefix + '.values.essentialGoods'),
      value: ShoppingHabitsKind.SHOP_RARELY_ONLY_BUYING_ESSENTIAL_GOODS,
    },
    { label: t(translationPrefix + '.values.averageShopper'), value: ShoppingHabitsKind.AVERAGE_SHOPPER },
    { label: t(translationPrefix + '.values.luxuryBrands'), value: ShoppingHabitsKind.SHOP_IN_LUXURY_BRANDS },
    {
      label: t(translationPrefix + '.values.sustainableBrands'),
      value: ShoppingHabitsKind.MOSTLY_BUY_SUSTAINABLE_BRANDS,
    },
  ];

  const hints = [t(translationPrefix + '.hint')];

  return (
    <Root>
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <RadioGroup>
        {options.map(({ value, label }) => {
          return (
            <Field key={value} name={fieldName} type="radio" value={value} validate={validators.isRequired}>
              {({ input }) => (
                <RadioButton
                  key={value}
                  text={label}
                  {...input}
                  onChange={event => {
                    const { value } = event.currentTarget;
                    input.onChange(value);
                  }}
                />
              )}
            </Field>
          );
        })}
      </RadioGroup>
      <Hints hints={hints} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 115px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const RadioGroup = styled.div`
  margin-bottom: 40px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;
