import { DEVICE } from '@net0-calc/common/assets/styles';
import { CalculatorFormValues } from 'common/types';
import React, { FC } from 'react';
import styled from 'styled-components';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { ClassOfServiceField, FlightHabitsField } from './components';

const FlightHabits: FC<WizardPageProps<CalculatorFormValues>> = props => {
  return (
    <Root>
      <FlightHabitsField {...props} />
      <ClassOfServiceField {...props} />
    </Root>
  );
};

export default FlightHabits;

const Root = styled.div`
  width: 100%;
  margin-bottom: 47px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;
