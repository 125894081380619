import {
  CarIcon,
  HomeIcon,
  PlaneIcon,
  CutleryIcon,
  EcoFriendlyIcon,
  ElectricityIcon,
  RecycleIcon,
  ShoppingIcon,
} from '@net0-calc/common/assets/svg';

import { CalculatorFormValues, StepItemProps, StepName } from 'common/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Diet,
  EcoFriendlyActivities,
  Electricity,
  FlightHabits,
  GettingAroundCity,
  HomeSize,
  Recycling,
  Shopping,
  WelcomePage,
} from '../components/wizard-form-pages';

const translationPrefix = 'dubaiPersonal.steps';

export const useSteps = () => {
  const { t } = useTranslation();
  const [currentStepIndex, changeCurrentStepIndex] = useState(0);

  const stepsMap: Record<StepName, StepItemProps<CalculatorFormValues>> = {
    [StepName.WELCOME]: {
      name: StepName.WELCOME,
      title: '',
      icon: null,
      page: <WelcomePage />,
    },
    [StepName.FLIGHT_HABITS]: {
      name: StepName.FLIGHT_HABITS,
      title: t(translationPrefix + '.flightHabits'),
      icon: <PlaneIcon />,
      page: <FlightHabits />,
    },
    [StepName.DIET]: {
      name: StepName.DIET,
      title: t(translationPrefix + '.diet'),
      icon: <CutleryIcon />,
      page: <Diet />,
    },
    [StepName.GETTING_AROUND_CITY]: {
      name: StepName.GETTING_AROUND_CITY,
      title: t(translationPrefix + '.gettingIntoCity'),
      icon: <CarIcon />,
      page: <GettingAroundCity />,
    },
    [StepName.SHOPPING]: {
      name: StepName.SHOPPING,
      title: t(translationPrefix + '.shopping'),
      icon: <ShoppingIcon />,
      page: <Shopping />,
    },
    [StepName.RECYCLING]: {
      name: StepName.RECYCLING,
      title: t(translationPrefix + '.recycling'),
      icon: <RecycleIcon />,
      page: <Recycling />,
    },
    [StepName.HOME_SIZE]: {
      name: StepName.HOME_SIZE,
      title: t(translationPrefix + '.homeSize'),
      icon: <HomeIcon />,
      page: <HomeSize />,
    },
    [StepName.ELECTRICITY]: {
      name: StepName.ELECTRICITY,
      title: t(translationPrefix + '.electricity'),
      icon: <ElectricityIcon />,
      page: <Electricity />,
    },
    [StepName.ECO_FRIENDLY]: {
      name: StepName.ECO_FRIENDLY,
      title: t(translationPrefix + '.ecoFriendly'),
      icon: <EcoFriendlyIcon />,
      page: <EcoFriendlyActivities />,
    },
  };

  const stepsNames = [
    StepName.WELCOME,
    StepName.FLIGHT_HABITS,
    StepName.DIET,
    StepName.GETTING_AROUND_CITY,
    StepName.SHOPPING,
    StepName.RECYCLING,
    StepName.HOME_SIZE,
    StepName.ELECTRICITY,
    StepName.ECO_FRIENDLY,
  ] as StepName[];

  const steps = stepsNames.map(stepName => stepsMap[stepName]);

  const currentStep = steps[currentStepIndex];
  const currentStepName = currentStep.name;
  const isLastStep = currentStepIndex === steps.length - 1;

  return [steps, { changeCurrentStepIndex, currentStepIndex, currentStep, currentStepName, isLastStep }] as const;
};
