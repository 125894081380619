import { Hints, RadioButton, QuestionTitle } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { DietKind } from 'common/types';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as validators from 'validators';

const translationPrefix = 'dubaiPersonal.pages.diet';

const fieldName = 'dietKind';

export const Diet = () => {
  const { t } = useTranslation();

  const options = [
    { label: t(translationPrefix + '.values.everything'), value: DietKind.EAT_EVERYTHING },
    { label: t(translationPrefix + '.values.lessMeal'), value: DietKind.TRY_TO_EAT_LESS_MEAT },
    { label: t(translationPrefix + '.values.pesceterian'), value: DietKind.PESCETARIAN },
    { label: t(translationPrefix + '.values.vegetarian'), value: DietKind.VEGETARIAN },
    { label: t(translationPrefix + '.values.vegan'), value: DietKind.VEGAN },
  ];

  const hints = [t(translationPrefix + '.hint1'), t(translationPrefix + '.hint2')];

  return (
    <Root>
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <RadioGroup>
        {options.map(({ value, label }) => {
          return (
            <Field key={value} name={fieldName} type="radio" value={value} validate={validators.isRequired}>
              {({ input }) => (
                <RadioButton
                  key={value}
                  text={label}
                  {...input}
                  onChange={event => {
                    const { value } = event.currentTarget;
                    input.onChange(value);
                  }}
                />
              )}
            </Field>
          );
        })}
      </RadioGroup>
      <Hints hints={hints} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 55px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const RadioGroup = styled.div`
  margin-bottom: 40px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;
