import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { useFetchAnswer } from '../MainPage/hooks';
import { Co2FootprintVO } from 'vo';
import {
  Factors,
  ReductionOpportunities,
  TipsToReduce,
  TopContainer,
} from 'pages/MainPage/components/Result/components';
import { MainLayout } from 'layouts';
import { Co2FootprintsVOs } from 'common/types';
import { Loader } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';

export const ResultsPage = () => {
  const { id } = useParams<{ id: string }>();

  const [answer, { isLoading }] = useFetchAnswer(id ?? null);

  const unit = 'kg';

  const [co2FootprintsVOs, setCo2FootprintsVOs] = useState<Co2FootprintsVOs>({
    diet: Co2FootprintVO.create(0, unit),
    ecoFriendlyActivities: Co2FootprintVO.create(0, unit),
    electricityAtHome: Co2FootprintVO.create(0, unit),
    flyingHabits: Co2FootprintVO.create(0, unit),
    gettingAroundCity: Co2FootprintVO.create(0, unit),
    homeSize: Co2FootprintVO.create(0, unit),
    recycling: Co2FootprintVO.create(0, unit),
    shoppingHabits: Co2FootprintVO.create(0, unit),
  });
  const co2FootprintSumVO = Co2FootprintVO.sum(Object.values(co2FootprintsVOs || {}));

  useEffect(() => {
    if (answer) {
      const { co2Footprint } = answer.answers;
      const {
        dietKg,
        ecoFriendlyActivitiesKg,
        electricityAtHomeKg,
        flyingHabitsKg,
        gettingAroundCityKg,
        homeSizeKg,
        recyclingKg,
        shoppingHabitsKg,
      } = co2Footprint;

      setCo2FootprintsVOs({
        diet: Co2FootprintVO.create(dietKg, unit),
        ecoFriendlyActivities: Co2FootprintVO.create(ecoFriendlyActivitiesKg, unit),
        electricityAtHome: Co2FootprintVO.create(electricityAtHomeKg, unit),
        flyingHabits: Co2FootprintVO.create(flyingHabitsKg, unit),
        gettingAroundCity: Co2FootprintVO.create(gettingAroundCityKg, unit),
        homeSize: Co2FootprintVO.create(homeSizeKg, unit),
        recycling: Co2FootprintVO.create(recyclingKg, unit),
        shoppingHabits: Co2FootprintVO.create(shoppingHabitsKg, unit),
      });
    }
  }, [answer]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size={80} color="var(--color2)" />
      </LoaderWrapper>
    );
  }

  return (
    <MainLayout isBackButtonVisible={false}>
      <Root>
        <TopContainer co2FootprintSumVO={co2FootprintSumVO} co2FootprintsVOs={co2FootprintsVOs} />
        <ReductionOpportunities />
        <Factors />
        <StyledTipsToReduce />
      </Root>
    </MainLayout>
  );
};
const Root = styled.div`
  width: 100%;
  min-height: 1200px;
  @media ${DEVICE.laptop} {
    padding-bottom: 25px;
  }
`;

const StyledTipsToReduce = styled(TipsToReduce)`
  margin-bottom: 0;
`;

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;
