import { RoomsPerPersonKind } from 'common/types';
import { Co2FootprintVO } from 'vo';

const YEAR_DAYS_COUNT = 365;

type Props = {
  roomsPerPersonKind?: RoomsPerPersonKind;
};

export default (props: Props) => {
  const { roomsPerPersonKind = RoomsPerPersonKind.LESS_THAN_ONE } = props;

  // values in kg
  const mapping: Record<RoomsPerPersonKind, number> = {
    [RoomsPerPersonKind.LESS_THAN_ONE]: 4 * YEAR_DAYS_COUNT,
    [RoomsPerPersonKind.ONE]: 7.5 * YEAR_DAYS_COUNT,
    [RoomsPerPersonKind.TWO]: 15 * YEAR_DAYS_COUNT,
    [RoomsPerPersonKind.THREE_OR_MORE]: 25 * YEAR_DAYS_COUNT,
  };

  const co2FootprintValueKg = mapping[roomsPerPersonKind];
  return Co2FootprintVO.create(co2FootprintValueKg, 'kg');
};
