import { DrinkingHomeWaterKind } from 'common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EcoFriendlyFieldStructure } from '../common';

const translationPrefix = 'dubaiPersonal.pages.ecoFriendlyActivities.homeWaterKind';

const fieldName = 'drinkingHomeWaterKind';

const HomeWaterKindField = () => {
  const { t } = useTranslation();
  const options = [
    { label: t(translationPrefix + '.values.waterFilter'), value: DrinkingHomeWaterKind.WATER_FILTER },
    {
      label: t(translationPrefix + '.values.oneLiterOrSmallerPlasticBotel'),
      value: DrinkingHomeWaterKind.ONE_LITRE_OR_SMALLER_PLASTIC_BOTTLES,
    },
    { label: t(translationPrefix + '.values.twentyGallonBottles'), value: DrinkingHomeWaterKind.TWENTY_GALLON_BOTTLES },
  ];

  return (
    <EcoFriendlyFieldStructure
      options={options}
      fieldName={fieldName}
      step={1}
      title={t(translationPrefix + '.title')}
    />
  );
};

export default HomeWaterKindField;
