import { ShoppingHabitsKind } from 'common/types';
import { Co2FootprintVO } from 'vo';

type Props = {
  shoppingHabitsKind?: ShoppingHabitsKind;
};

export default (props: Props) => {
  const { shoppingHabitsKind = ShoppingHabitsKind.AVERAGE_SHOPPER } = props;

  const mapping: Record<ShoppingHabitsKind, number> = {
    [ShoppingHabitsKind.SHOP_RARELY_ONLY_BUYING_ESSENTIAL_GOODS]: 148.325,
    [ShoppingHabitsKind.AVERAGE_SHOPPER]: 148.325,
    [ShoppingHabitsKind.SHOP_IN_LUXURY_BRANDS]: 148.325,
    [ShoppingHabitsKind.MOSTLY_BUY_SUSTAINABLE_BRANDS]: 148.325,
  };

  const co2FootprintValueKg = mapping[shoppingHabitsKind];
  return Co2FootprintVO.create(co2FootprintValueKg, 'kg');
};
