import * as React from 'react';
import { SVGProps } from 'react';
const SvgRecycleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      clipPath="url(#recycle-icon_svg__a)"
      stroke="#BCBBBB"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M6.46 10.234 3.085 16.08c-.962 1.667.24 3.75 2.165 3.75h2.712" />
      <path d="M8.282 13.732 7.014 9.256l-4.477 1.268M12.358 19.83h6.75c1.924 0 3.127-2.083 2.165-3.75l-1.355-2.347" />
      <path d="m14.476 16.503-3.242 3.337 3.336 3.242M17.54 9.596 14.166 3.75c-.962-1.667-3.367-1.667-4.33 0L8.62 5.856" />
      <path d="m13.6 9.425 4.511 1.14 1.14-4.511" />
    </g>
    <defs>
      <clipPath id="recycle-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRecycleIcon;
