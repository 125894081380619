import { CalculatorFormValues } from 'common/types';
import { config } from 'config';
import { HttpClient } from 'services';

export type Answer = {
  id: string;
  answers: {
    formValues: CalculatorFormValues;
    co2Footprint: {
      flyingHabitsKg: number;
      dietKg: number;
      gettingAroundCityKg: number;
      recyclingKg: number;
      shoppingHabitsKg: number;
      homeSizeKg: number;
      electricityAtHomeKg: number;
      ecoFriendlyActivitiesKg: number;
    };
  };
};

export const api = {
  sendAnswers({ answers }: { answers: any }) {
    const url = ['calculators', config.API_CALCULATOR_ID, 'answers'].join('/');
    return HttpClient.post(url, { answers });
  },
  sendEmail({ answerId, email }: { answerId: string; email: string }) {
    const url = ['answers', answerId, 'send-email'].join('/');
    return HttpClient.get(url, { params: { email } });
  },
  getAnswerById(answerId: string) {
    const url = ['answer', answerId].join('/');
    return HttpClient.get<Answer>(url);
  },
  getResultImage(answerId: string, html: string) {
    const url = ['answer', answerId, 'generate-html-to-png'].join('/');
    return HttpClient.post<string>(url, { html });
  },
};
