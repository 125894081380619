import { Hints, RadioButton, QuestionTitle } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { RoomsPerPersonKind } from 'common/types';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as validators from 'validators';

const translationPrefix = 'dubaiPersonal.pages.homeSize';

const fieldName = 'roomsPerPersonKind';

const HomeSize = () => {
  const { t } = useTranslation();

  const options = [
    { label: t(translationPrefix + '.values.lessThanOne'), value: RoomsPerPersonKind.LESS_THAN_ONE },
    { label: t(translationPrefix + '.values.one'), value: RoomsPerPersonKind.ONE },
    { label: t(translationPrefix + '.values.two'), value: RoomsPerPersonKind.TWO },
    { label: t(translationPrefix + '.values.threeOrMore'), value: RoomsPerPersonKind.THREE_OR_MORE },
  ];

  const hints = [t(translationPrefix + '.hint')];

  return (
    <Root>
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <RadioGroup>
        {options.map(({ value, label }) => {
          return (
            <Field key={value} name={fieldName} type="radio" value={value} validate={validators.isRequired}>
              {({ input }) => (
                <RadioButton
                  key={value}
                  text={label}
                  {...input}
                  onChange={event => {
                    const { value } = event.currentTarget;
                    input.onChange(value);
                  }}
                />
              )}
            </Field>
          );
        })}
      </RadioGroup>
      <Hints hints={hints} />
    </Root>
  );
};

export default HomeSize;

const Root = styled.div`
  width: 100%;
  margin-bottom: 127px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const RadioGroup = styled.div`
  margin-bottom: 40px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;
