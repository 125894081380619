import { CalculatorFormValues, HomeAirConditionerOperatingKind, StepName } from 'common/types';
import { useState } from 'react';
import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';
import { co2FootprintCalculator } from 'co2-footprint-calculator';

export const useStepCalculationHandlers = ({ formValues }: { formValues: CalculatorFormValues }) => {
  /**
   * We rely on that fields of values to be defined in at each step,
   * because this calculation will be execute after filling inputs on step
   */
  const values = formValues as Required<CalculatorFormValues>;

  const [co2FootprintsVOs, setCo2FootprintsVOs] = useState({
    flyingHabits: Co2FootprintVO.create(0, 'kg'),
    diet: Co2FootprintVO.create(0, 'kg'),
    gettingAroundCity: Co2FootprintVO.create(0, 'kg'),
    recycling: Co2FootprintVO.create(0, 'kg'),
    shoppingHabits: Co2FootprintVO.create(0, 'kg'),
    homeSize: Co2FootprintVO.create(0, 'kg'),
    electricityAtHome: Co2FootprintVO.create(0, 'kg'),
    ecoFriendlyActivities: Co2FootprintVO.create(0, 'kg'),
  });
  const updateCo2FootprintsVOs = (statePart: Partial<typeof co2FootprintsVOs>) => {
    setCo2FootprintsVOs(currentState => ({
      ...currentState,
      ...statePart,
    }));
  };

  const stepCalculationHandlers: Record<StepName, () => void> = {
    [StepName.WELCOME]: utils.noop,
    [StepName.FLIGHT_HABITS]: () => {
      const flyingHabits = co2FootprintCalculator.calculateFlyingHabits(values);
      updateCo2FootprintsVOs({ flyingHabits });
    },
    [StepName.DIET]: () => {
      const diet = co2FootprintCalculator.calculateDiet(values);
      updateCo2FootprintsVOs({ diet });
    },
    [StepName.GETTING_AROUND_CITY]: () => {
      const gettingAroundCity = co2FootprintCalculator.calculateGettingAroundCity(values);
      updateCo2FootprintsVOs({ gettingAroundCity });
    },
    [StepName.SHOPPING]: () => {
      const shoppingHabits = co2FootprintCalculator.calculateShoppingHabits(values);
      updateCo2FootprintsVOs({ shoppingHabits });
    },
    [StepName.RECYCLING]: () => {
      const recycling = co2FootprintCalculator.calculateRecycling(values);
      updateCo2FootprintsVOs({ recycling });
    },
    [StepName.HOME_SIZE]: () => {
      const homeSize = co2FootprintCalculator.calculateHomeSize(values);
      updateCo2FootprintsVOs({ homeSize });
    },
    [StepName.ELECTRICITY]: () => {
      const electricityAtHome = co2FootprintCalculator.calculateElectricityAtHome(values);
      updateCo2FootprintsVOs({ electricityAtHome });
    },
    // TODO: rename step to ECO_FRIENDLY_ACTIVITIES
    [StepName.ECO_FRIENDLY]: () => {
      const ecoFriendlyActivities = co2FootprintCalculator.calculateEcoFriendlyActivities(values);
      updateCo2FootprintsVOs({ ecoFriendlyActivities });

      const sum = (a: number, b: number) => a + b;
      const subtract = (a: number, b: number) => a - b;

      const hasSmartCoolingTechnology =
        values.homeAirConditionerOperatingKind === HomeAirConditionerOperatingKind.SMART_COOLING_TECHNOLOGY;
      const operate = hasSmartCoolingTechnology ? subtract : sum;

      const electricityAtHomeCo2FootrpintKg = co2FootprintsVOs.electricityAtHome.kg;
      const electricityAtHome30PercentKg = electricityAtHomeCo2FootrpintKg * 0.3;
      const newElectricityAtHomeCo2FootrpintKg = operate(electricityAtHomeCo2FootrpintKg, electricityAtHome30PercentKg);

      const electricityAtHome = Co2FootprintVO.create(newElectricityAtHomeCo2FootrpintKg, 'kg');
      updateCo2FootprintsVOs({ electricityAtHome });
    },
  };

  const handleStepCalculations = (currentStepName: StepName) => {
    const handle = stepCalculationHandlers[currentStepName];
    handle();
  };

  return { co2FootprintsVOs, handleStepCalculations, updateCo2FootprintsVOs };
};
