import { DEVICE } from '@net0-calc/common/assets/styles';
import React from 'react';
import styled from 'styled-components';
import { AirConditionerOperatingKindField, FoodBuyingLocationKindField, HomeWaterKindField } from './components';

const EcoFriendlyActivities = () => {
  return (
    <Root>
      <HomeWaterKindField />
      <AirConditionerOperatingKindField />
      <FoodBuyingLocationKindField />
    </Root>
  );
};

export default EcoFriendlyActivities;

const Root = styled.div`
  width: 100%;
  margin-bottom: 47px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;
