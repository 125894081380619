import { DEVICE } from '@net0-calc/common/assets/styles';
import React, { memo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as validators from 'validators';
import { StepInStepItem } from 'components';
import { ThrowingAwayFoodWithoutRecyclingKind } from 'common/types';
import { Hints, RadioButton, QuestionTitle } from '@net0-calc/common/components/ui';

const translationPrefix = 'dubaiPersonal.pages.recycling.recycleFood';

const fieldName = 'throwingAwayFoodWithoutRecyclingKind';

const RecyclingFood = () => {
  const { t } = useTranslation();
  const options = [
    { label: t(translationPrefix + '.values.none'), value: ThrowingAwayFoodWithoutRecyclingKind.NONE },
    { label: t(translationPrefix + '.values.low'), value: ThrowingAwayFoodWithoutRecyclingKind.PERCENT_10 },
    {
      label: t(translationPrefix + '.values.medium'),
      value: ThrowingAwayFoodWithoutRecyclingKind.PERCENT_BETWEEN_10_AND_30,
    },
    { label: t(translationPrefix + '.values.hight'), value: ThrowingAwayFoodWithoutRecyclingKind.PERCENT_ABOVE_30 },
  ];

  const hints = [t(translationPrefix + '.hint')];

  return (
    <Root>
      <StepInStepItem stepNumber={2} isLastStep />
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <RadioGroup>
        {options.map(({ value, label }, index) => {
          return (
            <Field key={index} name={fieldName} type="radio" value={value} validate={validators.isRequired}>
              {({ input }) => (
                <RadioButton
                  text={label}
                  {...input}
                  onChange={event => {
                    const { value } = event.currentTarget;
                    input.onChange(value);
                  }}
                />
              )}
            </Field>
          );
        })}
      </RadioGroup>
      <Hints hints={hints} />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: calc(100% - 12px);
  margin-left: 12px;
  padding-left: 19px;

  @media ${DEVICE.laptop} {
    width: calc(100% - 22px);
    padding-left: 37px;
    margin-left: 22px;
  }
`;

const RadioGroup = styled.div`
  margin-bottom: 40px;

  @media ${DEVICE.laptop} {
    margin-bottom: 50px;
  }
`;

export default memo(RecyclingFood);
