import { CalculatorFormValues } from 'common/types';
import { useState } from 'react';

const initialFormValues: CalculatorFormValues = {
  welcome: [],
};

export const useFormValues = () => {
  const [formValues, setFormValues] = useState<CalculatorFormValues>(initialFormValues);
  return { initialFormValues, formValues, setFormValues } as const;
};
