import * as React from 'react';
import { SVGProps } from 'react';
const SvgEcoFriendlyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#eco-friendly-icon_svg__a)" stroke="#BCBBBB" strokeWidth={2}>
      <path d="M5 12a7 7 0 0 1 7-7h7v7a7 7 0 1 1-14 0Z" />
      <path d="m5 19 8-8" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="eco-friendly-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEcoFriendlyIcon;
