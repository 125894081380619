import { HomeAirConditionerOperatingKind } from 'common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EcoFriendlyFieldStructure } from '../common';

const translationPrefix = 'dubaiPersonal.pages.ecoFriendlyActivities.homeAirConditionerOperatingKind';

const fieldName = 'homeAirConditionerOperatingKind';

const AirConditionerOperatingKindField = () => {
  const { t } = useTranslation();
  const options = [
    {
      label: t(translationPrefix + '.values.alwaysOn'),
      value: HomeAirConditionerOperatingKind.ALWAYS_ON,
    },
    {
      label: t(translationPrefix + '.values.offWhenOwnerNotHome'),
      value: HomeAirConditionerOperatingKind.OFF_WHEN_OWNER_NOT_HOME,
    },
    {
      label: t(translationPrefix + '.values.dontFactorGeographyOfProduce'),
      value: HomeAirConditionerOperatingKind.SMART_COOLING_TECHNOLOGY,
    },
  ];

  return (
    <EcoFriendlyFieldStructure
      options={options}
      fieldName={fieldName}
      step={2}
      title={t(translationPrefix + '.title')}
    />
  );
};

export default AirConditionerOperatingKindField;
