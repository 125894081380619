import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import { GoodIcon } from '@net0-calc/common/assets/svg';

gsap.registerPlugin(ScrollTrigger);

const translationPrefix = 'dubaiPersonal.pages.result.ideas';

export const ReductionOpportunities = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 500px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0.2,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 0.7,
      });
  }, []);

  return (
    <Root ref={containerRef}>
      <Title>
        <EmojiContainer>🌱</EmojiContainer> <span>{t(`${translationPrefix}.title`)}</span>
      </Title>
      <StepWrap>
        <StepTitleWrap>
          <GoodIcon />
          <span>{t(`${translationPrefix}.values.recycle.title`)}</span>
        </StepTitleWrap>
        <StepParagraph>{t(`${translationPrefix}.values.recycle.description`)}</StepParagraph>
      </StepWrap>
      <StepWrap>
        <StepTitleWrap>
          <GoodIcon />
          <span>{t(`${translationPrefix}.values.publicTransport.title`)}</span>
        </StepTitleWrap>
        <StepParagraph>{t(`${translationPrefix}.values.publicTransport.description`)}</StepParagraph>
      </StepWrap>
      <StepWrap>
        <StepTitleWrap>
          <GoodIcon />
          <span>{t(`${translationPrefix}.values.sustainableFood.title`)}</span>
        </StepTitleWrap>
        <StepParagraph>{t(`${translationPrefix}.values.sustainableFood.description`)}</StepParagraph>
      </StepWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: 845px;
  margin-bottom: 40px;
  @media ${DEVICE.laptop} {
    margin-bottom: 60px;
  }
`;

const Title = styled.div`
  ${TYPOGRAPHY.titleSemibold32}
  margin-bottom: 20px;
  color: var(--color6);
  display: flex;
  @media ${DEVICE.laptop} {
    margin-bottom: 34px;
  }
`;

const StepWrap = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const StepTitleWrap = styled.div`
  ${TYPOGRAPHY.boydRegular20}
  color: var(--color6);

  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  column-gap: 14px;
  & svg {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
  }
  & span {
    &:first-line {
      line-height: 100% !important;
    }
  }
`;

const StepParagraph = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color9);
  margin-left: 40px;
`;

const emojiPulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
`;

const EmojiContainer = styled.div`
  ${TYPOGRAPHY.titleSemibold32}
  cursor: pointer;
  margin-right: 8px;
  &:hover {
    animation: ${emojiPulseAnimation} 0.2s ease-in normal;
  }
`;
