import { RecyclingHabitsKind, ThrowingAwayFoodWithoutRecyclingKind } from 'common/types';
import { Co2FootprintVO } from 'vo';

type Props = {
  recyclingHabitsKind?: RecyclingHabitsKind;
  throwingAwayFoodWithoutRecyclingKind?: ThrowingAwayFoodWithoutRecyclingKind;
};

export default (props: Props) => {
  const {
    recyclingHabitsKind = RecyclingHabitsKind.DONT_RECYCLE,
    throwingAwayFoodWithoutRecyclingKind = ThrowingAwayFoodWithoutRecyclingKind.PERCENT_ABOVE_30,
  } = props;

  const byRecyclingHabitsCo2Mapping: Record<RecyclingHabitsKind, number> = {
    [RecyclingHabitsKind.RECYCLE_EVERYTHING_DAILY]: 0,
    [RecyclingHabitsKind.RECYCLE_ONLY_PLASTIC_DAILY]: 510,
    [RecyclingHabitsKind.TRY_RECYCLE_WHENEVER_CAN]: 510,
    [RecyclingHabitsKind.DONT_RECYCLE]: 904,
  };

  const byThrowingAwayFoodCo2Mapping: Record<ThrowingAwayFoodWithoutRecyclingKind, number> = {
    [ThrowingAwayFoodWithoutRecyclingKind.NONE]: 0,
    [ThrowingAwayFoodWithoutRecyclingKind.PERCENT_10]: 80,
    [ThrowingAwayFoodWithoutRecyclingKind.PERCENT_BETWEEN_10_AND_30]: 240,
    [ThrowingAwayFoodWithoutRecyclingKind.PERCENT_ABOVE_30]: 800,
  };

  const co2FootprintValueKg =
    byRecyclingHabitsCo2Mapping[recyclingHabitsKind] +
    byThrowingAwayFoodCo2Mapping[throwingAwayFoodWithoutRecyclingKind];

  return Co2FootprintVO.create(co2FootprintValueKg, 'kg');
};
