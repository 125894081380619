import styled from 'styled-components/macro';
import { FC, useEffect, useState } from 'react';
import { DEVICE } from '@net0-calc/common/assets/styles';
import {
  ActionButtons,
  Factors,
  ReceiveResult,
  ReductionOpportunities,
  TipsToReduce,
  TopContainer,
} from './components';
import { CalculatorFormValues, Co2FootprintsVOs } from 'common/types';
import { Co2FootprintVO } from 'vo';

type Props = {
  formValues: CalculatorFormValues;
  co2FootprintSumVO: Co2FootprintVO;
  co2FootprintsVOs: Co2FootprintsVOs;
  answerId?: string;
};

export const Result: FC<Props> = ({ co2FootprintSumVO, co2FootprintsVOs, formValues, answerId = '' }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Root>
      <TopContainer co2FootprintSumVO={co2FootprintSumVO} co2FootprintsVOs={co2FootprintsVOs} />
      <ReductionOpportunities />
      <Factors />
      <TipsToReduce />
      <ActionButtons answerId={answerId} />
      <ReceiveResult answerId={answerId} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${DEVICE.laptop} {
    padding-bottom: 25px;
  }
`;
