import { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { useTranslation } from 'react-i18next';
import { BadIcon } from '@net0-calc/common/assets/svg';

gsap.registerPlugin(ScrollTrigger);

const translationPrefix = 'dubaiPersonal.pages.result.factors';

export const Factors = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 600px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  return (
    <Root ref={containerRef}>
      <Title>
        <EmojiContainer>🌳</EmojiContainer> {t(translationPrefix + '.title')}
      </Title>
      <StepTitleWrap>
        <StyledBadIcon />
        {t(translationPrefix + '.values.notOverShopping')}
      </StepTitleWrap>
      <StepTitleWrap>
        <StyledBadIcon />
        {t(translationPrefix + '.values.recycleWheneverPossible')}
      </StepTitleWrap>
      <StepTitleWrap>
        <StyledBadIcon />
        {t(translationPrefix + '.values.notTravelTooMuch')}
      </StepTitleWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: 845px;
  margin-bottom: 40px;
  @media ${DEVICE.laptop} {
    margin-bottom: 60px;
  }
`;

const Title = styled.div`
  ${TYPOGRAPHY.boydRegular20}
  margin-bottom: 20px;
  color: var(--color6);
  display: flex;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-bottom: 32px;
  }
`;

const StepTitleWrap = styled.div`
  ${TYPOGRAPHY.boydRegular20}
  color: var(--color6);

  display: flex;
  align-items: flex-start;
  & svg {
    overflow: visible;
    width: 26px;
    margin-right: 14px;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const emojiPulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
`;

const EmojiContainer = styled.div`
  ${TYPOGRAPHY.titleSemibold32}
  cursor: pointer;
  margin-right: 8px;
  &:hover {
    animation: ${emojiPulseAnimation} 0.2s ease-in normal;
  }
`;

const StyledBadIcon = styled(BadIcon)`
  path {
    stroke: var(--color13);
  }
`;
