import { i18n } from 'services';

export const isNumber = (value: unknown) => {
  if (typeof value === 'undefined') {
    return undefined;
  }
  const currentValue = Number(value);
  const hasLeadingZero = Number(value).toString() !== value;

  return hasLeadingZero || isNaN(currentValue) ? i18n.t('formValidationErrors.isNumber') : undefined;
};
