import { DrinkingHomeWaterKind, FoodBuyingLocationKind } from 'common/types';
import { Co2FootprintVO } from 'vo';

type Props = {
  drinkingHomeWaterKind?: DrinkingHomeWaterKind;
  foodBuyingLocationKind?: FoodBuyingLocationKind;
};

export default (props: Props) => {
  const {
    drinkingHomeWaterKind = DrinkingHomeWaterKind.ONE_LITRE_OR_SMALLER_PLASTIC_BOTTLES,
    foodBuyingLocationKind = FoodBuyingLocationKind.DONT_FACTOR_GEOGRAPHY_OF_PRODUCE,
  } = props;

  const byDrinkingHomeWaterKindMapping: Record<DrinkingHomeWaterKind, number> = {
    [DrinkingHomeWaterKind.WATER_FILTER]: 0,
    [DrinkingHomeWaterKind.ONE_LITRE_OR_SMALLER_PLASTIC_BOTTLES]: 0.2 * 3.116 * 365,
    [DrinkingHomeWaterKind.TWENTY_GALLON_BOTTLES]: 2.27 * 20 * 1.6 * 3.269,
  };
  const byFoodBuyingLocationKindMapping: Record<FoodBuyingLocationKind, number> = {
    [FoodBuyingLocationKind.MAINLY_BUY_LOCAL_PRODUCE]: 16.5,
    [FoodBuyingLocationKind.DONT_FACTOR_GEOGRAPHY_OF_PRODUCE]: 76.23,
  };

  const co2FootprintValueKg =
    byDrinkingHomeWaterKindMapping[drinkingHomeWaterKind] + byFoodBuyingLocationKindMapping[foodBuyingLocationKind];
  return Co2FootprintVO.create(co2FootprintValueKg, 'kg');
};
