import { RadioButton, QuestionTitle } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { StepInStepItem } from 'components';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { Option } from 'types';
import * as validators from 'validators';

type Props = {
  step: number;
  isLastStep?: boolean;
  title: string;
  options: Option[];
  fieldName: string;
};

const EcoFriendlyFieldStructure: FC<Props> = ({ fieldName, options, step, title, isLastStep }) => {
  return (
    <Root $isLastStep={isLastStep}>
      <StepInStepItem isLastStep={isLastStep} stepNumber={step} />
      <QuestionTitle>{title}</QuestionTitle>
      <div>
        {options.map(({ value, label }) => {
          return (
            <Field key={value} name={fieldName} type="radio" value={value} validate={validators.isRequired}>
              {({ input }) => {
                return (
                  <RadioButton
                    text={label}
                    {...input}
                    onChange={event => {
                      const { value } = event.currentTarget;
                      input.onChange(value);
                    }}
                  />
                );
              }}
            </Field>
          );
        })}
      </div>
    </Root>
  );
};

const Root = styled.div<{ $isLastStep?: boolean }>`
  position: relative;
  width: calc(100% - 12px);
  padding: 0 0 40px 19px;
  margin-left: 12px;
  ${({ $isLastStep }) => ($isLastStep ? '' : `border-left: 2px solid var(--color14);`)}
  @media ${DEVICE.laptop} {
    width: calc(100% - 22px);
    margin-left: 22px;
    padding: 0 0 70px 37px;
    transform: none;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
`;

export default EcoFriendlyFieldStructure;
