import { GettingAroundCityKind, KilometersRidingPerYearKind } from 'common/types';
import { Co2FootprintVO } from 'vo';

const calculatePetrolOrDieselVehicleCo2FootrpintKg = (props: {
  kilometersRidingPerYearKind: KilometersRidingPerYearKind;
  customKilometersRidingPerYearNumber?: number;
}) => {
  const { kilometersRidingPerYearKind, customKilometersRidingPerYearNumber } = props;

  if (!kilometersRidingPerYearKind) {
    throw Error();
  }

  const getCoeff = () => {
    if (kilometersRidingPerYearKind === KilometersRidingPerYearKind.CUSTOM_NUMBER) {
      if (typeof customKilometersRidingPerYearNumber === undefined) {
        const message = [
          '"customKilometersRidingPerYearNumber" is required',
          `if "kilometersRidingPerYearKind" is "${KilometersRidingPerYearKind.CUSTOM_NUMBER}"`,
        ].join(' ');

        throw new Error(message);
      }
    }

    const coeffMapping: Record<KilometersRidingPerYearKind, number> = {
      [KilometersRidingPerYearKind.UP_TO_5000]: 5000,
      [KilometersRidingPerYearKind.BETWEEN_5000_AND_10000]: 10000,
      [KilometersRidingPerYearKind.BETWEEN_10000_AND_15000]: 15000,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [KilometersRidingPerYearKind.CUSTOM_NUMBER]: customKilometersRidingPerYearNumber!,
    };

    return coeffMapping[kilometersRidingPerYearKind];
  };

  const coeff = getCoeff();
  return coeff * 0.170115;
};

type Props = {
  gettingAroundCityKind?: GettingAroundCityKind;
  kilometersRidingPerYearKind?: KilometersRidingPerYearKind;
  customKilometersRidingPerYearNumber?: number;
};

export default (props: Props) => {
  const {
    kilometersRidingPerYearKind,
    customKilometersRidingPerYearNumber,
    gettingAroundCityKind = GettingAroundCityKind.MOSTLY_USE_PUBLIC_TRANSPORT,
  } = props;

  if (gettingAroundCityKind === GettingAroundCityKind.MOSTLY_USE_PETROL_OR_DIESEL_VEHICLE) {
    if (!kilometersRidingPerYearKind) {
      const message = `"kilometersRidingPerYearKind" is required for petrol or diesel vehicle co2 footprint calculation`;
      throw new Error(message);
    }
    const co2FootprintValueKg = calculatePetrolOrDieselVehicleCo2FootrpintKg({
      kilometersRidingPerYearKind,
      customKilometersRidingPerYearNumber,
    });
    return Co2FootprintVO.create(co2FootprintValueKg, 'kg');
  }

  const mapping: Record<GettingAroundCityKind, number> = {
    [GettingAroundCityKind.MOSTLY_USE_PUBLIC_TRANSPORT]: 0.10312 * 15000,
    [GettingAroundCityKind.MOSTLY_USE_ELECTRIC_VEHICLE]: 0.06954 * 15000,
    [GettingAroundCityKind.MOSTLY_CYCLE]: 0,
    [GettingAroundCityKind.MOSTLY_WALK]: 0,
    [GettingAroundCityKind.MOSTLY_USE_PETROL_OR_DIESEL_VEHICLE]: 0, // never be executed, because if statement handle it
  };

  const co2FootprintValueKg = mapping[gettingAroundCityKind];
  return Co2FootprintVO.create(co2FootprintValueKg, 'kg');
};
