import { DEVICE } from '@net0-calc/common/assets/styles';
import { FormApi, FormState } from 'final-form';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { toast } from 'services';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Co2FootprintVO } from 'vo';
import { Button } from '@net0-calc/common/components/ui';
// import { CO2Footprint } from 'components';

const handleError = (error: any) => {
  let message = 'Something went wrong. Please try again later.';
  if (error.response?.data?.messages) {
    message = error.response?.data?.messages.join(', ');
  } else if (error.message) {
    message = error.message;
  }

  toast.error(message);
};

export interface WizardPageProps<T> {
  form?: FormApi<T, Partial<T>>;
  values?: T;
}

interface WizardFormProps<T> {
  pages: ReactElement<WizardPageProps<T>>[];
  currentPageIndex: number;
  onStepSubmit: (values: T) => Promise<void>;
  initialValues: T;
  co2FootprintSumVO: Co2FootprintVO;
  onChangeFormSpy?: (state: FormState<T>) => void;
}

const SWIP_ANIMATION_DURATION_MS = 2000;

export function WizardForm<T>({
  pages,
  currentPageIndex,
  onStepSubmit,
  initialValues,
  onChangeFormSpy,
}: WizardFormProps<T>) {
  const currentPage = pages[currentPageIndex];
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const [isSwipAnimationEnabled, setIsSwipAnimationEnabled] = useState(false);
  const enableSwipAnimation = () => setIsSwipAnimationEnabled(true);
  const disableSwipAnimation = () => setIsSwipAnimationEnabled(false);

  const handleContinue = (values: T) => async () => {
    setIsSubmitting(true);
    try {
      await onStepSubmit(values);

      enableSwipAnimation();
      setTimeout(() => {
        disableSwipAnimation();
      }, SWIP_ANIMATION_DURATION_MS);
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const buttonTitle = useMemo(() => {
    if (isSubmitting) {
      return t('general.wizardForm.submitButton.loading');
    }
    if (currentPageIndex === 0) {
      return t('general.wizardForm.submitButton.getStarted');
    }
    return t('general.wizardForm.submitButton.continue');
  }, [currentPageIndex, isSubmitting]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [currentPageIndex]);

  return (
    <Form
      initialValues={initialValues}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSubmit={(_: T) => {}} // onSubmit required, but not used
      render={({ hasValidationErrors, values, form }) => (
        <>
          {onChangeFormSpy && <FormSpy<T> onChange={onChangeFormSpy} />}
          <Root $isAnimationEnabled={isSwipAnimationEnabled} id="page-container">
            {currentPage &&
              React.cloneElement(currentPage, {
                form,
                values,
              })}
            <ButtonWrapper>
              <StyledButton
                isDisabled={hasValidationErrors || isSwipAnimationEnabled || isSubmitting}
                title={buttonTitle}
                type="button"
                onClick={handleContinue(values)}
              />
            </ButtonWrapper>
          </Root>
        </>
      )}
    />
  );
}

const Root = styled.form<{ $isAnimationEnabled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  @media ${DEVICE.laptop} {
    height: auto;
    justify-content: flex-start;
  }

  @keyframes wizardFormAnimation {
    0% {
      transform: translateX(0);
    }
    49% {
      transform: translateX(-50%);
      opacity: 0;
    }
    50% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  ${({ $isAnimationEnabled }) =>
    $isAnimationEnabled && `animation: wizardFormAnimation ${SWIP_ANIMATION_DURATION_MS}ms ease-in-out 1;`}
`;
const ButtonWrapper = styled('div')`
  margin-top: auto;
  margin-bottom: 0;
`;
const StyledButton = styled(Button)`
  padding: 13px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
