import { DietKind } from 'common/types';
import { Co2FootprintVO } from 'vo';

type Props = {
  dietKind?: DietKind;
};

export default (props: Props) => {
  const { dietKind = DietKind.EAT_EVERYTHING } = props;

  // values in kg
  const mapping: Record<DietKind, number> = {
    [DietKind.EAT_EVERYTHING]: 1300,
    [DietKind.TRY_TO_EAT_LESS_MEAT]: 700,
    [DietKind.PESCETARIAN]: 450,
    [DietKind.VEGETARIAN]: 300,
    [DietKind.VEGAN]: 0,
  };

  const resultCo2FootrpintKg = mapping[dietKind];
  return Co2FootprintVO.create(resultCo2FootrpintKg, 'kg');
};
