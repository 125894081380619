import { Hints, QuestionTitle } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { AirlineTicketClass, CalculatorFormValues } from 'common/types';
import { ChipRadioButton, StepInStepItem } from 'components';
import { WizardPageProps } from 'pages/MainPage/components/WizardForm/WizardForm';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const translationPrefix = 'dubaiPersonal.pages.flightHabits.classOfServices';

const ClassOfServiceField: FC<WizardPageProps<CalculatorFormValues>> = ({ values }) => {
  const { t } = useTranslation();

  const options = [
    {
      title: t(`${translationPrefix}.values.economy`),
      value: AirlineTicketClass.ECONOMY,
    },
    { title: t(`${translationPrefix}.values.business`), value: AirlineTicketClass.BUSINESS },
    { title: t(`${translationPrefix}.values.firstClass`), value: AirlineTicketClass.FIRST_CLASS },
  ];

  const hints = [t(translationPrefix + '.hint1'), t(translationPrefix + '.hint2'), t(translationPrefix + '.hint3')];

  return (
    <Root>
      <StyledStepInStepItem isLastStep stepNumber={2} />
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <ChipsGroup>
        {options.map(({ title, value }) => {
          return (
            <Field key={value} name="usualAirlineTicketClass" type="radio" value={value}>
              {({ input }) => (
                <StyledChipRadioButton
                  id={`${value}-option`}
                  {...input}
                  type="checkbox"
                  onChange={event => {
                    const { value } = event.currentTarget;

                    if (value === values?.usualAirlineTicketClass) {
                      input.onChange(undefined);
                      return;
                    }

                    input.onChange(value);
                  }}>
                  {title}
                </StyledChipRadioButton>
              )}
            </Field>
          );
        })}
      </ChipsGroup>
      <Hints hints={hints} />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: calc(100% - 12px);

  padding-left: 19px;
  margin-left: 12px;

  @media ${DEVICE.laptop} {
    padding-left: 37px;
    margin-left: 22px;
    width: calc(100% - 22px);
  }
`;

const ChipsGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media ${DEVICE.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media ${DEVICE.laptop} {
    margin-bottom: 30px;
  }
`;

const StyledChipRadioButton = styled(ChipRadioButton)`
  width: fit-content;
  &:not(&:last-child) {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const StyledStepInStepItem = styled(StepInStepItem)`
  top: 0;
  left: -3.4%;
`;

export default ClassOfServiceField;
