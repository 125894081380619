import { HaveRenewableElectricityAtHome } from 'common/types';
import { Co2FootprintVO } from 'vo';

type Props = {
  haveRenewableElectricityAtHome?: HaveRenewableElectricityAtHome;
};

export default (props: Props) => {
  const { haveRenewableElectricityAtHome = HaveRenewableElectricityAtHome.DONT_KNOW } = props;

  // TODO: clarify values
  const mapping: Record<HaveRenewableElectricityAtHome, number> = {
    [HaveRenewableElectricityAtHome.YES]: 11330 * 0.011,
    [HaveRenewableElectricityAtHome.NO]: 11330 * 0.4333,
    [HaveRenewableElectricityAtHome.DONT_KNOW]: 11330 * 0.24165,
  };

  const co2FootprintValueKg = mapping[haveRenewableElectricityAtHome];
  return Co2FootprintVO.create(co2FootprintValueKg, 'kg');
};
