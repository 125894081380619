import calculateGettingAroundCity from './calculate-getting-around-city';
import calculateElectricityAtHome from './calculate-electricity-at-home';
import calculateFlyingHabits from './calculate-flying-habits';
import calculateRecycling from './calculate-recycling';
import calculateHomeSize from './calculate-home-size';
import calculateShoppingHabits from './calculate-shopping-habits';
import calculateDiet from './calculate-diet';
import calculateEcoFriendlyActivities from './calculate-eco-friendly-activities';

/**
 * Calculations Documentation
 * https://docs.google.com/document/d/1bAgsN3rMe_W4nf3ml0-wFAWZQyMfetijMHk6qzWxH-c/edit
 */

const co2FootprintCalculator = {
  calculateGettingAroundCity,
  calculateElectricityAtHome,
  calculateFlyingHabits,
  calculateRecycling,
  calculateHomeSize,
  calculateShoppingHabits,
  calculateDiet,
  calculateEcoFriendlyActivities,
};

export { co2FootprintCalculator };
