import { FoodBuyingLocationKind } from 'common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EcoFriendlyFieldStructure } from '../common';

const translationPrefix = 'dubaiPersonal.pages.ecoFriendlyActivities.foodBuyingLocationKind';

const fieldName = 'foodBuyingLocationKind';

const FoodBuyingLocationKindField = () => {
  const { t } = useTranslation();
  const options = [
    {
      label: t(translationPrefix + '.values.mainlyBuyLocalProduce'),
      value: FoodBuyingLocationKind.MAINLY_BUY_LOCAL_PRODUCE,
    },
    {
      label: t(translationPrefix + '.values.dontFactorGeographyOfProduce'),
      value: FoodBuyingLocationKind.DONT_FACTOR_GEOGRAPHY_OF_PRODUCE,
    },
  ];

  return (
    <EcoFriendlyFieldStructure
      options={options}
      fieldName={fieldName}
      step={3}
      title={t(translationPrefix + '.title')}
      isLastStep
    />
  );
};

export default FoodBuyingLocationKindField;
