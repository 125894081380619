import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { CalculatorFormValues, GettingAroundCityKind, KilometersRidingPerYearKind } from 'common/types';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import * as validators from 'validators';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { combineValidators } from 'validators/combineValidators';
import { Hints, Input, RadioButton, QuestionTitle } from '@net0-calc/common/components/ui';

const translationPrefix = 'dubaiPersonal.pages.gettingAroundCity';

export const GettingAroundCity: FC<WizardPageProps<CalculatorFormValues>> = ({ values }) => {
  const { t } = useTranslation();

  const transportOptions = [
    { value: GettingAroundCityKind.MOSTLY_WALK, label: t(translationPrefix + '.values.walk') },
    { value: GettingAroundCityKind.MOSTLY_CYCLE, label: t(translationPrefix + '.values.cycle') },
    {
      value: GettingAroundCityKind.MOSTLY_USE_PUBLIC_TRANSPORT,
      label: t(translationPrefix + '.values.publicTransport'),
    },
    {
      value: GettingAroundCityKind.MOSTLY_USE_ELECTRIC_VEHICLE,
      label: t(translationPrefix + '.values.electricVehicle'),
    },
    {
      value: GettingAroundCityKind.MOSTLY_USE_PETROL_OR_DIESEL_VEHICLE,
      label: t(translationPrefix + '.values.petrolOrDieselVehicle'),
    },
  ];

  const petrolOrDieselVehicleOptions = [
    {
      value: KilometersRidingPerYearKind.UP_TO_5000,
      label: t(translationPrefix + '.customPetrolOrDieselVehicleValues.values.low'),
    },
    {
      value: KilometersRidingPerYearKind.BETWEEN_5000_AND_10000,
      label: t(translationPrefix + '.customPetrolOrDieselVehicleValues.values.medium'),
    },
    {
      value: KilometersRidingPerYearKind.BETWEEN_10000_AND_15000,
      label: t(translationPrefix + '.customPetrolOrDieselVehicleValues.values.hight'),
    },
    {
      value: KilometersRidingPerYearKind.CUSTOM_NUMBER,
      label: t(translationPrefix + '.customPetrolOrDieselVehicleValues.values.custom'),
    },
  ];

  const hints = [t(translationPrefix + '.hint')];

  const isPetrolOrDieselVehicle =
    values?.gettingAroundCityKind === GettingAroundCityKind.MOSTLY_USE_PETROL_OR_DIESEL_VEHICLE;

  const isCustomKilometersRidingPerYearKind =
    values?.kilometersRidingPerYearKind === KilometersRidingPerYearKind.CUSTOM_NUMBER;

  return (
    <Root>
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <RadioGroup $isPetrolOrDieselVehicle={isPetrolOrDieselVehicle}>
        {transportOptions.map(({ value, label }) => {
          return (
            <Field key={value} name="gettingAroundCityKind" type="radio" value={value} validate={validators.isRequired}>
              {({ input }) => (
                <RadioButton
                  key={value}
                  text={label}
                  {...input}
                  onChange={event => {
                    const { value } = event.currentTarget;
                    input.onChange(value);
                  }}
                />
              )}
            </Field>
          );
        })}
      </RadioGroup>
      {isPetrolOrDieselVehicle && (
        <PetrolOrDieselVehicle>
          <Description>{t(translationPrefix + '.customPetrolOrDieselVehicleValues.description')}</Description>
          <ExtendRadioGroup $isCustomKilometersRidingPerYearKind={isCustomKilometersRidingPerYearKind}>
            {petrolOrDieselVehicleOptions.map(({ value, label }) => {
              return (
                <Field
                  key={value}
                  name="kilometersRidingPerYearKind"
                  type="radio"
                  value={value}
                  validate={validators.isRequired}>
                  {({ input }) => (
                    <RadioButton
                      key={value}
                      text={label}
                      {...input}
                      onChange={event => {
                        const { value } = event.currentTarget;
                        input.onChange(value);
                      }}
                    />
                  )}
                </Field>
              );
            })}
          </ExtendRadioGroup>
          {isCustomKilometersRidingPerYearKind && (
            <Field
              name="customKilometersRidingPerYearNumber"
              label={t(translationPrefix + '.customPetrolOrDieselVehicleValues.inputLabel')}
              placeholder="0"
              type="number"
              validate={combineValidators(
                validators.isArrayOrStringMaxLength(10),
                validators.isNegativeNumber,
                validators.isNumber,
                validators.isRequired,
                validators.isZero,
              )}
              component={StyledInput}
            />
          )}
        </PetrolOrDieselVehicle>
      )}
      <Hints hints={hints} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 75px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const RadioGroup = styled.div<{ $isPetrolOrDieselVehicle: boolean }>`
  ${({ $isPetrolOrDieselVehicle }) =>
    $isPetrolOrDieselVehicle
      ? css`
          margin-bottom: 20px;
        `
      : css`
          margin-bottom: 40px;
        `}

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;

const ExtendRadioGroup = styled.div<{ $isCustomKilometersRidingPerYearKind: boolean }>`
  ${({ $isCustomKilometersRidingPerYearKind }) => {
    return !$isCustomKilometersRidingPerYearKind
      ? css`
          margin-bottom: 40px;

          @media ${DEVICE.laptop} {
            margin-bottom: 24px;
          }
        `
      : css`
          margin-bottom: 24px;
        `;
  }}
`;

const PetrolOrDieselVehicle = styled.div`
  padding-left: 36px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.bodyMedium16}
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 40px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;
