import { WizardPageProps } from 'pages/MainPage/components/WizardForm/WizardForm';
import { ReactElement, ReactNode } from 'react';
import { Co2FootprintVO } from 'vo';

export type MapPoint = {
  lat: number;
  lng: number;
};

export enum AirlineTicketClass {
  ECONOMY = 'economy',
  BUSINESS = 'business',
  FIRST_CLASS = 'first_class',
}

export enum FlightFrequencyKind {
  RARELY = 'rarely',
  OCCASIONALLY = 'occasionally',
  REGULARLY = 'regularly',
}

export enum DietKind {
  EAT_EVERYTHING = 'eat-everything',
  TRY_TO_EAT_LESS_MEAT = 'try-to-eat-less-meat',
  PESCETARIAN = 'pescetarian',
  VEGETARIAN = 'vegetarian',
  VEGAN = 'vegan',
}

export enum GettingAroundCityKind {
  MOSTLY_WALK = 'mostly-walk',
  MOSTLY_CYCLE = 'mostly-cycle',
  MOSTLY_USE_PUBLIC_TRANSPORT = 'mostly-use-public-transport',
  MOSTLY_USE_ELECTRIC_VEHICLE = 'mostly-use-electric-vehicle',
  MOSTLY_USE_PETROL_OR_DIESEL_VEHICLE = 'mostly-use-petrol-or-diesel-vehicle',
}

// TODO: change values to low, medium, high
export enum KilometersRidingPerYearKind {
  UP_TO_5000 = 'up-to-5000',
  BETWEEN_5000_AND_10000 = 'between-5000-and-10000',
  BETWEEN_10000_AND_15000 = 'between-10000-and-15000',
  CUSTOM_NUMBER = 'custom-number',
}

export enum ShoppingHabitsKind {
  SHOP_RARELY_ONLY_BUYING_ESSENTIAL_GOODS = 'shop-rarely-only-buying-essential-goods',
  AVERAGE_SHOPPER = 'average-shopper',
  SHOP_IN_LUXURY_BRANDS = 'shop-in-luxury-brands',
  MOSTLY_BUY_SUSTAINABLE_BRANDS = 'mostly-buy-sustainable-brands',
}

export enum RecyclingHabitsKind {
  RECYCLE_EVERYTHING_DAILY = 'recycle-everything-daily',
  RECYCLE_ONLY_PLASTIC_DAILY = 'recycle-only-plastic-daily',
  TRY_RECYCLE_WHENEVER_CAN = 'try-recycle-whenever-can',
  DONT_RECYCLE = 'dont-recycle',
}

export enum ThrowingAwayFoodWithoutRecyclingKind {
  NONE = 'none',
  PERCENT_10 = 'percent-10',
  PERCENT_BETWEEN_10_AND_30 = 'percent-between-10-and-30',
  PERCENT_ABOVE_30 = 'percent-above-30',
}

export enum RoomsPerPersonKind {
  LESS_THAN_ONE = 'less-than-one',
  ONE = 'one',
  TWO = 'two',
  THREE_OR_MORE = 'three-or-more',
}

export enum HaveRenewableElectricityAtHome {
  YES = 'yes',
  NO = 'no',
  DONT_KNOW = 'dont-know',
}

export enum DrinkingHomeWaterKind {
  WATER_FILTER = 'water-filter',
  ONE_LITRE_OR_SMALLER_PLASTIC_BOTTLES = 'one-litre-or-smaller-plastic-bottles',
  TWENTY_GALLON_BOTTLES = 'twenty-gallon-bottles',
}

export enum HomeAirConditionerOperatingKind {
  ALWAYS_ON = 'always-on',
  OFF_WHEN_OWNER_NOT_HOME = 'off-when-owner-not-home',
  SMART_COOLING_TECHNOLOGY = 'smart-cooling-technology',
}

export enum FoodBuyingLocationKind {
  MAINLY_BUY_LOCAL_PRODUCE = 'mainly-buy-local-produce',
  DONT_FACTOR_GEOGRAPHY_OF_PRODUCE = 'dont-factor-geography-of-produce',
}

export interface CalculatorFormValues {
  welcome?: string[];

  usualAirlineTicketClass?: AirlineTicketClass;
  flightFrequencyKind?: FlightFrequencyKind;
  singleShortFlightsNumber?: number;
  singleMidRangeFlightsNumber?: number;
  singleLongHaulFlightsNumber?: number;

  dietKind?: DietKind;

  gettingAroundCityKind?: GettingAroundCityKind;
  kilometersRidingPerYearKind?: KilometersRidingPerYearKind;
  customKilometersRidingPerYearNumber?: number;

  recyclingHabitsKind?: RecyclingHabitsKind;
  throwingAwayFoodWithoutRecyclingKind?: ThrowingAwayFoodWithoutRecyclingKind;

  shoppingHabitsKind?: ShoppingHabitsKind;
  roomsPerPersonKind?: RoomsPerPersonKind;

  haveRenewableElectricityAtHome?: HaveRenewableElectricityAtHome;

  drinkingHomeWaterKind?: DrinkingHomeWaterKind;
  homeAirConditionerOperatingKind?: HomeAirConditionerOperatingKind;
  foodBuyingLocationKind?: FoodBuyingLocationKind;
}

export enum StepName {
  WELCOME = 'WELCOME',
  FLIGHT_HABITS = 'FLIGHT_HABITS',
  DIET = 'DIET',
  GETTING_AROUND_CITY = 'GETTING_AROUND_CITY',
  SHOPPING = 'SHOPPING',
  RECYCLING = 'RECYCLING',
  HOME_SIZE = 'HOME_SIZE',
  ELECTRICITY = 'ELECTRICITY',
  ECO_FRIENDLY = 'ECO_FRIENDLY',
}

export interface StepItemProps<T> {
  name: StepName;
  title: string;
  icon: ReactNode;
  page: ReactElement<WizardPageProps<T>>;
}

export enum LoadingStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export enum Language {
  ENGLISH = 'en',
  DEUTSCH = 'de',
  FRANÇAIS = 'fr',
  ITALIANO = 'it',
}

export type Co2FootprintsVOs = {
  flyingHabits: Co2FootprintVO;
  diet: Co2FootprintVO;
  gettingAroundCity: Co2FootprintVO;
  recycling: Co2FootprintVO;
  shoppingHabits: Co2FootprintVO;
  homeSize: Co2FootprintVO;
  electricityAtHome: Co2FootprintVO;
  ecoFriendlyActivities: Co2FootprintVO;
};
