import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { CalculatorFormValues } from 'common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { WizardPageProps } from '../../WizardForm/WizardForm';
import { StepTitle } from '@net0-calc/common/components/ui';
import { ClockIcon } from '@net0-calc/common/assets/svg';

const translationPrefix = 'dubaiPersonal.pages.welcome';

const WelcomePage: React.FC<WizardPageProps<CalculatorFormValues>> = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <StepTitle dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.title`) }}></StepTitle>
      <DescriptionsContainer>
        <Description dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.subtitle1`) }}></Description>
        <Description>{t(`${translationPrefix}.subtitle2`)}</Description>
        <Description dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.subtitle3`) }}></Description>
      </DescriptionsContainer>
      <EstimationSection>
        <StyledClockIcon />
        <Text>{t(`${translationPrefix}.estimation`)}</Text>
      </EstimationSection>
    </Root>
  );
};

export default WelcomePage;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 139px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
    height: auto;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

const DescriptionsContainer = styled.div`
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

const EstimationSection = styled.div`
  display: flex;
  align-items: center;
`;

const StyledClockIcon = styled(ClockIcon)`
  margin-right: 10px;
  stroke: var(--color2);
`;

const Text = styled(Description)`
  margin-bottom: 0px;
`;
