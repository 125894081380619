import { DEVICE } from '@net0-calc/common/assets/styles';
import React from 'react';
import styled from 'styled-components';
import { RecyclingFood, RecyclingHabits } from './components';

export const Recycling = () => {
  return (
    <Root>
      <RecyclingHabits />
      <RecyclingFood />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 47px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;
