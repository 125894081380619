import { DEVICE } from '@net0-calc/common/assets/styles';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Field } from 'react-final-form';
import { WizardPageProps } from 'pages/MainPage/components/WizardForm/WizardForm';
import { CalculatorFormValues, FlightFrequencyKind } from 'common/types';
import { StepInStepItem } from 'components';
import * as validators from 'validators';
import { combineValidators } from 'validators/combineValidators';
import { intRegexp } from '../../../../../../../common/regex';
import { Input, RadioButton, QuestionTitle } from '@net0-calc/common/components/ui';

const translationPrefix = 'dubaiPersonal.pages.flightHabits.flightHabitsField';

const fieldName = 'flightFrequencyKind';

const FlightHabitsField: FC<WizardPageProps<CalculatorFormValues>> = ({ form, values }) => {
  const { t } = useTranslation();
  const [isMoreSelected, setIsMoreSelected] = useState(false);

  const options = [
    { value: FlightFrequencyKind.RARELY, label: t(translationPrefix + '.values.rarelyFly') },
    { value: FlightFrequencyKind.OCCASIONALLY, label: t(translationPrefix + '.values.occasionally') },
    { value: FlightFrequencyKind.REGULARLY, label: t(translationPrefix + '.values.regularly') },
  ];

  useEffect(() => {
    if (values?.singleShortFlightsNumber || values?.singleLongHaulFlightsNumber || values?.singleShortFlightsNumber) {
      setIsMoreSelected(true);
    }
  }, []);

  const atLeastOneFlightNumberIsFilled =
    (values?.singleMidRangeFlightsNumber || 0) > 0 ||
    (values?.singleShortFlightsNumber || 0) > 0 ||
    (values?.singleLongHaulFlightsNumber || 0) > 0;

  const customFlightsValidators = combineValidators(
    validators.isArrayOrStringMaxLength(10),
    validators.isNumber,
    validators.isNegativeNumber,
    atLeastOneFlightNumberIsFilled ? () => undefined : validators.isRequired,
    atLeastOneFlightNumberIsFilled ? () => undefined : validators.isZero,
  );

  return (
    <Root>
      <StepInStepItem stepNumber={1} />
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <RadioGroup $isMoreSelected={isMoreSelected}>
        {options.map(({ value, label }) => {
          return (
            <Field key={value} name={fieldName} type="radio" value={value}>
              {({ input }) => (
                <RadioButton
                  key={value}
                  text={label}
                  {...input}
                  type="checkbox"
                  onChange={event => {
                    const { value } = event.currentTarget;

                    if (value === values?.flightFrequencyKind) {
                      input.onChange(undefined);
                      return;
                    }

                    input.onChange(value);
                  }}
                  onClick={() => setIsMoreSelected(false)}
                />
              )}
            </Field>
          );
        })}
        <StyledRadioButton
          text={t(translationPrefix + '.values.preciseData')}
          checked={isMoreSelected}
          type="checkbox"
          onChange={() => setIsMoreSelected(prev => !prev)}
          onClick={() => form?.change('flightFrequencyKind', undefined)}
        />
      </RadioGroup>
      {isMoreSelected && (
        <InputsGroup>
          <Field
            name="singleShortFlightsNumber"
            label={t(translationPrefix + '.values.inputLabels.shortFlights')}
            placeholder={0}
            maxLength={10}
            component={Input}
            validate={customFlightsValidators}
            type="number"
            regex={intRegexp}
          />
          <Field
            name="singleMidRangeFlightsNumber"
            label={t(translationPrefix + '.values.inputLabels.midRangeFlights')}
            placeholder={0}
            maxLength={10}
            component={Input}
            validate={customFlightsValidators}
            type="number"
            regex={intRegexp}
          />
          <Field
            name="singleLongHaulFlightsNumber"
            label={t(translationPrefix + '.values.inputLabels.longHaulFlights')}
            placeholder={0}
            maxLength={10}
            component={StyledInput}
            validate={customFlightsValidators}
            type="number"
            regex={intRegexp}
          />
        </InputsGroup>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 12px);

  border-left: 2px solid var(--color14);

  padding: 0 0 40px 19px;
  margin-left: 12px;

  @media ${DEVICE.laptop} {
    width: calc(100% - 22px);
    padding: 0 0 70px 37px;
    margin-left: 22px;
  }
`;

const RadioGroup = styled.div<{ $isMoreSelected: boolean }>`
  ${({ $isMoreSelected }) =>
    !$isMoreSelected
      ? ''
      : css`
          margin-bottom: 20px;

          @media ${DEVICE.laptop} {
            margin-bottom: 24px;
          }
        `}
`;

const StyledRadioButton = styled(RadioButton)`
  margin-bottom: 0;
`;

const InputsGroup = styled.div`
  padding-left: 36px;
`;

const StyledInput = styled(Input)`
  & > div {
    margin-bottom: 0;
  }
`;

export default FlightHabitsField;
