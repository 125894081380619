import * as React from 'react';
import { SVGProps } from 'react';
const SvgShoppingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      clipPath="url(#shopping-icon_svg__a)"
      stroke="#BCBBBB"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M7.95 21.95a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM18.95 21.95a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5.07 7h16.97l-1.65 7.43A2 2 0 0 1 18.44 16H8.66a2 2 0 0 1-2-1.58L4 2H1" />
    </g>
    <defs>
      <clipPath id="shopping-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgShoppingIcon;
