import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import React, { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { formatWeightValue } from 'utils';
import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';
import { Co2FootprintsVOs } from 'common/types';

interface ChartDonutSectionProps {
  co2FootprintsVOs: Co2FootprintsVOs;
  accommodationCO2FootprintVO: Co2FootprintVO;
  className?: string;
}

const translationPrefix = 'dubaiPersonal.pages.result.donutChart';
const summaryValuesPrefix = 'dubaiPersonal.pages.result.summary.values';

export const ChartDonutSection: FC<ChartDonutSectionProps> = ({
  co2FootprintsVOs,
  accommodationCO2FootprintVO,
  className,
}) => {
  const { t } = useTranslation();

  const co2FootprintPerAttendeeVO = Co2FootprintVO.sum(Object.values(co2FootprintsVOs));
  const resultPerAttendee = co2FootprintPerAttendeeVO.monthlyValue;

  const co2FootprintLevel = useMemo(() => {
    const co2FootprintKg = co2FootprintPerAttendeeVO.monthlyValue;
    if (co2FootprintKg <= 300) {
      return 'Low';
    }
    if (co2FootprintKg >= 1500) {
      return 'High';
    }
    return 'Average';
  }, [co2FootprintPerAttendeeVO.kg]);

  const co2footPrintLevelNames = {
    ['Low']: t(translationPrefix + '.level.low'),
    ['Average']: t(translationPrefix + '.level.average'),
    ['High']: t(translationPrefix + '.level.hight'),
  };

  const labels = [
    t(summaryValuesPrefix + '.flightHabits'),
    t(summaryValuesPrefix + '.diet'),
    t(summaryValuesPrefix + '.gettingAroundCity'),
    t(summaryValuesPrefix + '.shopping'),
    t(summaryValuesPrefix + '.recycling'),
    t(summaryValuesPrefix + '.accommodation'),
  ];

  const series = [
    co2FootprintsVOs.flyingHabits.formattedKg,
    co2FootprintsVOs.diet.formattedKg,
    co2FootprintsVOs.gettingAroundCity.formattedKg,
    co2FootprintsVOs.shoppingHabits.formattedKg,
    co2FootprintsVOs.recycling.formattedKg,
    accommodationCO2FootprintVO.formattedKg,
  ];

  const colors = [
    'var(--chart-color6)',
    'var(--chart-color1)',
    'var(--chart-color2)',
    'var(--chart-color3)',
    'var(--chart-color4)',
    'var(--chart-color5)',
  ];

  return (
    <Root className={className}>
      <StyledChart
        options={{
          states: {
            hover: {
              filter: {
                type: 'none',
              },
            },
            active: {
              filter: {
                type: 'none',
              },
            },
          },
          stroke: { width: 0 },
          colors,
          legend: {
            show: false,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: (value: number) => {
                return utils.formatWeightValue(value);
              },
            },
          },
          labels,
          dataLabels: {
            enabled: false,
          },
        }}
        series={series}
        type="donut"
      />
      <ChartResultContainer>
        <PerPersonValue>{formatWeightValue(resultPerAttendee)}</PerPersonValue>
        <PerPersonSubtitle>{t(translationPrefix + '.label')}</PerPersonSubtitle>
      </ChartResultContainer>
      <Text>{t(translationPrefix + '.levelDescription')}</Text>
      <FootprintLevel>{co2footPrintLevelNames[co2FootprintLevel]}</FootprintLevel>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: 30px 0 30px;
  border-radius: 20px;
  border: 2px solid var(--color4);
  margin: 0 auto;

  @media ${DEVICE.tablet} {
    min-width: 300px;
    max-width: 340px;
  }
  @media ${DEVICE.laptop} {
    margin: 0;
  }
`;
const PerPersonValue = styled.div`
  ${TYPOGRAPHY.titleSemibold24}
  color: var(--color6);
`;

const PerPersonSubtitle = styled.div`
  ${TYPOGRAPHY.bodyMedium16}
  color: var(--color6);
`;
const ChartResultContainer = styled.div`
  position: absolute;
  top: 28.5%;
  width: 100%;
  text-align: center;
`;

const Text = styled.div`
  margin-bottom: 16px;
  padding: 0 30px;
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  text-align: center;
`;

const FootprintLevel = styled.div`
  ${TYPOGRAPHY.titleSemibold32}
  color: var(--color6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledChart = styled(Chart)`
  position: relative;
  margin-bottom: 17px;

  .apexcharts-tooltip {
    color: var(--color6) !important;
  }
`;
