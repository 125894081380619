import { RadioButton, QuestionTitle } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { RecyclingHabitsKind } from 'common/types';
import { StepInStepItem } from 'components';
import React, { memo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as validators from 'validators';

const translationPrefix = 'dubaiPersonal.pages.recycling.recyclingHabits';

const fieldName = 'recyclingHabitsKind';

const RecyclingHabits = () => {
  const { t } = useTranslation();
  const options = [
    { label: t(translationPrefix + '.values.everything'), value: RecyclingHabitsKind.RECYCLE_EVERYTHING_DAILY },
    { label: t(translationPrefix + '.values.plastic'), value: RecyclingHabitsKind.RECYCLE_ONLY_PLASTIC_DAILY },
    { label: t(translationPrefix + '.values.whenever'), value: RecyclingHabitsKind.TRY_RECYCLE_WHENEVER_CAN },
    { label: t(translationPrefix + '.values.noRecycle'), value: RecyclingHabitsKind.DONT_RECYCLE },
  ];

  return (
    <Root>
      <StepInStepItem stepNumber={1} />
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <div>
        {options.map(({ value, label }) => {
          return (
            <Field key={value} name={fieldName} type="radio" value={value} validate={validators.isRequired}>
              {({ input }) => {
                return (
                  <RadioButton
                    text={label}
                    {...input}
                    onChange={event => {
                      const { value } = event.currentTarget;
                      input.onChange(value);
                    }}
                  />
                );
              }}
            </Field>
          );
        })}
      </div>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: calc(100% - 12px);
  padding: 0 0 40px 19px;
  border-left: 2px solid var(--color14);
  margin-left: 12px;

  @media ${DEVICE.laptop} {
    width: calc(100% - 22px);
    padding: 0 0 70px 37px;
    margin-left: 22px;
  }
`;

export default memo(RecyclingHabits);
